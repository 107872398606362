<template>
    <div>
      <div class="text--secondary" style=" height:50vh;">
            <b>利用規約</b><br>
            第1条 （利用約款の適用）<br>
            1.株式会社ナレッジコミュニケーション（以下、「当社」といいます。）は、ナレコムVR（本サービス）の利用約款（以下、「本約款」といいます。）を定め、本約款に基づき契約者に提供します。<br>
            2.本約款は、当社が提供する本サービスの利用を目的とする契約（以下、「利用契約」といいます。）の内容及びその申込み方法等について定めます。 <br>
            3.契約者は、本約款を遵守して、本サービスを受けるものとします。<br><br>
          
            第2条 （提供内容）<br>
            1.本サービスはMicrosoft HoloLens及びMicrosoft Azureを用いて以下に関連するサービ スを提供します。<br>
              ・HoloLensでの表示機能：アップロードした3Dデータ及び関連するデータをHoloLensで表示及びそれに関連する機能  <br>
              ・空間認識・経路表示機能：空間を認識し、HoloLensもしくは関連する機器に経路を表示及びそれに関連する機能 <br><br>

            第3条 （利用約款の変更）<br>
            1.当社は、実施する日を定めて本約款を変更する事があります。その場合、1ヶ月前に契約者に変更内容を通知するものとします。なお、契約者の権利義務に重要な影響を及ぼさないと当社が判断した場合（例：新しいサービスメニューや機能の追加に伴う、本約款への追記）は、この限りではありません。 <br>
            2.前項の変更が行われた場合、料金その他の提供条件は、変更後の本サービスの利用約款が適用されます。 <br><br>
          
            第4条（適用範囲）<br>
            本サービスには、本約款とは別途に個別の利用約款が設けられている場合があります。<br>
            個別の利用約款が本約款と異なる定めをしている場合は、個別の利用約款が優先されます。<br><br>
          
            第5条（クラウド事業者が定める利用約款）<br>
            本サービスは、 Microsoft Corporation（以下「Microsoft」という）が提供するMicrosoft Azure (以下「Azure」といいます。)を利用したものです。<br>
            本サービスの利用に際してクラウド事業者が定める利用約款を逸脱するサービスの提供や利用はできません。
            Microsoftが定める利用約款：http://azure.microsoft.com/ja-jp/support/legal/<br><br>
          
            第6条（契約の申込）<br>
            1.本サービスの申込は当社が別に定める申込書及び注文書を利用し、提出するものとします。<br>
            2.申込みは本約款を承認した上で行い、利用契約は、当社における審査、手続等を経た上で、当社が利用を承認した後に成立するものとします。但し、審査、手続等のために追加で資料が必要と当社が判断する場合、申込者は当社が指定する資料を提出しなければなりません。<br><br>
          
            第7条 （最低利用期間と途中解約）<br>
            1.本サービス及び、本サービスに付加されるオプションサービスの最低利用期間は、事前に協議がある場合を除き、利用契約の成立日から起算して12ヶ月間とします。 <br>
            2.利用契約後に追加されたオプションサービスの最低利用期間も同様に12ヶ月間とします。但し、本サービスの利用期間が満了した場合、追加されたオプションサービスの利用期間内であったとしても、追加されたオプションサービスも当然に期間満了により終了するものとし、返金等は行うことができません。 <br>
            3.ただし、無償で提供するプランに関しましては、項番1,2で記載されている最低利用期間及び途中解約の事項に関して適用されません。 <br><br>
          
            第8条（自動更新と解約）<br>
            1.契約者から特に申し出がない場合は、契約期間が更に1ヶ月間の自動更新がなされるものとし、その後も同様とします。<br>
            2.契約者が利用契約を解約する場合、1ヶ月前までに当社に解約を通知する書面（電磁的方法によるものを含む）を提出し、当該書面記載の解約日の翌日をもって本サービスの提供を停止します。<br><br>
          
            第9条（サービスの第三者への委託）<br>
            当社は当社の責任および負担において、本サービスの業務の一部または全部を、適当と判断する第三者に委託して行わせることができるものとします。<br><br>
          
            第10条（サービス内容の変更）<br>
            契約者が、本サービス内容の変更を希望する場合は、当社が別途定める方法によりサービス内容の変更を申込むものとします。尚、サービス内容の変更については、第6条で定める最低利用期間に限らずできるものとします。 <br><br>
          
            第11条（契約者情報の変更）<br>
            契約者は、当社へ届け出た契約者情報に変更があったときは、変更内容とその変更の事実を証明する書類を、当社が別途定める方法により、すみやかに当社に提出しなければなりません。 
            当社への提出がなかったことで契約者が不利益を被ったとしても、当社は一切その責任を負わないものとします。 <br><br>
          
            第12条（料金）<br>
            1.契約者は、本サービスの利用にあたって、別途提示する料金規定に規定された料金を、当社の指定する方法により支払うものとします。<br>
            2.当社は、1ヶ月前に書面で通知することにより、料金の改定または部分的変更ができるものとし、改定後または変更後は、契約者はその料金を所定の手続きで支払わなければなりません。<br>
            3.契約者は、前項の通知を受けた日から1ヶ月以内に限り、当社に対して書面で通知することにより、利用契約を直ちに解約することができます。この場合、第7条第2項の規定は適用しないものとします。<br><br>
          
            第13条（当社による本サービス提供の停止等）<br>
            1.当社は、契約者に次に掲げる事由があるときは、利用期間内であっても契約者へ何ら通知すること無く本サービスの提供をいつでも停止又は利用契約の解除をできるものとします。<br>
            (1)利用契約上の債務を履行しなかったとき<br>
            (2)本約款の規定に違反したとき<br>
             (3)当社への申告、届出内容に虚偽の記載があったとき<br>
             (4)破産・民事再生・会社更生又は特別清算の手続開始決定等の申立てをしたとき、またはこれらがされたとき<br>
             (5)当社あるいは第三者の名誉、信用、プライバシー等の人格的利益を侵害する行為、あるいはそのおそれのある行為<br>
             (6)当社あるいは第三者の著作権、その他の知的財産権を侵害する行為、あるいはそのおそれのある行為<br>
             (7)当社の本サービスの提供を妨害する行為、あるいはそのおそれのある行為<br>
             (8)第三者の本サービスの利用に支障を与える方法あるいは態様において本サービスを利用する行為、あるいはそのおそれのある行為<br>
             (9)コンピュータウィルス等他人の業務を妨害するあるいはそのおそれのあるコンピュータ・プログラムを本サービスを利用して使用したり、第三者に提供する行為、あるいはそのおそれのある行為<br>
             (10)その他、他人の法的利益を侵害したり、公序良俗に反する行為<br>
             (11)その他、不適切と明らかに判断できるとき<br>
            2.前項により利用契約が取消となった契約者は、当社が取消した利用月末までに発生した全ての債務を当社の指定する方法で支払うものとします。<br><br>
          
            第14条（サービスの保守・中断・変更）<br>
            1.当社は本サービスの稼働状態を良好に保つために、随時その運用を一時停止の上、保守点検を行うことができるものとします。 <br>
            2.前項の場合、原則として事前に契約者にその旨を通知しますが、緊急の場合には通知することなく、一時停止の上、保守点検を行うことができるものとします。<br>
            3.当社は不慮の事故、不可抗力等のやむを得ない事由により、本サービスの運用を中断できるものとします。<br>
            4.当社は、天災、事変その他の非常事態が発生し、または発生するおそれがある時は、災害の予防もしくは救援、交通、通信もしくは電力の供給の確保または秩序の維持に必要な事項を内容とする通信、その他の公共利益のために緊急に行うことを要する通信を優先的に取り扱うため、本サービスの利用を制限する措置をとることがあります。<br>
            5.当社は契約者に事前に通知した上で、本サービスの内容の追加及び変更、廃止をすることができるものとします。<br>
            6.当社は前各項及びこれに類する事由により、サービス提供の遅延または中断等が発生してもこれに起因する契約者または他の第三者が被った損害について一切の責任を負わないものとします。ただし、当該サービス提供の遅延または中断等が当社の責任に基づくものでよる場合は、この限りではありません。<br><br>
          
            第15条（割増金）<br>
            契約者が料金等の支払いを怠った場合、その額に年14.6％の割合で計算した金額を違約金（割増金）として当社が指定する期日までに支払うこととします。<br><br>
          
            第16条（データ等の取扱い）<br>
            本サービスにおけるサーバーのデータが、当社の責めに帰す場合以外で滅失、毀損、漏洩、その他本来の利用目的以外に使用されたとしても、その結果発生する直接あるいは間接の損害について、当社はいかなる責任も負わないものとします。<br>
          
            第17条（データのバックアップ）<br>
            当社はサーバー設備の故障又は停止等の復旧に対応するために、契約ディレクトリ内のデータをバックアップすることがあります。契約者はあらかじめこれを了承するものとします。<br><br>
          
            第18条（著作権）<br>
            契約者が、本サービスを通じて他の契約者、並びに当社の著作物、創作物を使用するにあたっては、著作権法等の定める事項を遵守して適正な使用をするものとします。<br><br>
          
            第19条（情報の管理）<br>
            当社は、本サービスにより業務上知り得た事実を契約者の事前の承諾がある場合を除き、秘密として取り扱います。<br>
            ただし、公安当局からの捜査上の要請に基づいて書面による正式な協力要請があった場合、契約者の合意を得ずに情報の開示ができるものとします。<br><br>
          
            第20条（連絡・通知）<br>
            1.本サービスに関する問合せその他契約者から当社に対する連絡の回答通知、又は、本規約の変更に関する通知その他当社から契約者に対する連絡若しくは通知は、当社の定める方法で行うものとします。<br>
            2.当社に登録された契約者の住所、ＦＡＸ、メールアドレス等のうち少なくともいずれか1つにあてて通知を行った場合には、不到達となった場合でも通常到達すべき時に到達したものとみなすものとします。<br><br>
          
            第21条（損害賠償請求）<br>
            契約者が本約款に違反し当社に損害を与えた場合、当社は当該契約者に対して損害賠償請求を行う場合があります。<br><br>
          
            第22条（免責事項）<br>
            1.当社は、本サービス及び本サービスを通じて他のネットワークサービスを利用することにより情報等が破損または滅失したことによる損害、若しくは契約者が本サービスから得た情報等に起因して生じた損害について、その原因の如何によらず、一切の賠償の責任を負わないものとします。<br>
            2.当社は、本サービスを通じて提供する情報について、契約者の特定の目的に適合すること、期待する機能、商品的価値、完全性、正確性、適用性、有用性等いかなる保証も負わないものとします。<br>
            3.当社は、サービスの保守・中断・変更及びそれ以外の事由により、サービス提供の遅延または中断等が発生してもこれに起因する契約者または他の第三者が被った損害について一切の責任を負わないものとします。ただし、当該原因が当社の責めに帰す場合については、この限りではありません。<br>
            4.当社は、当社のシステム内に保管された契約者の個別データについて一切の責任を負いません。なお、理由のいかんを問わず、利用契約が効力を失った後、当社はその元契約者の個別ファイルを削除する権利を有するものとします。<br>
            5.当社は、クラウド事業者自体を起因とするサービスの中断、データの損失が発生した場合において、直接あるいは間接の損害について、いかなる責任も負わないものとします。<br><br>
          
            第23条（賠償責任の範囲）<br>
            1.当社は本サービスを提供すべき場合において、当社の責に帰すべき理由により、契約者に対し本サービスを提供しなかったときは、契約者からの請求により、その利用月における料金額を限度として損害の賠償をします。なお、契約者が当該月から1ヶ月を経過する日までに当該請求を行使しなかったときは、契約者はその権利を失うものとします。<br>
            2.何らかの理由により当社が責任を負う場合であっても、当社は契約者の被った損害につき過去3ヶ月間に契約者が当社に支払った対価の金額を超えて賠償する責任を負わないものとし、また、付随的損害、間接損害、特別損害、将来の損害及び逸失利益にかかる損害については、賠償する責任を負わないものとします。<br>
          
            第24条（不可抗力）<br>
            当社および契約者は、災害、地変、火災、労働争議、騒乱、法令の変更、大規模な停電、その他の不可抗力にもとづく利用契約上の債務の不履行または遅延については、相手方に対して何ら責任を負わないこととします。<br><br>
          
            第25条（協議）<br>
            本サービスの利用に関して、本約款、個別の利用約款、当社の指導により解決できない問題が生じた場合には契約者との間で双方誠意をもって協議し、これを解決するものとします。<br><br>
          
            第26条（管轄裁判所）<br>
            契約者と当社との間で本サービスの利用に関して紛争が生じた場合は、準拠法を日本法とし、東京地方裁判所を第一審の専属的合意管轄裁判所とします。<br><br>
          
            平成30年 7月20日制定<br>
            令和3年2月22日改定 
          </div><br>
    
    </div>
      

</template> 

<script>


export default {
  name: 'DocTerms',
  data () {
    return {
     
    }
  },
}

</script>
